<template>
  <div>
    <!-- <HeadCards /> -->
    <full-table
      :other-filter-props="{
        add: true,
        search: true,
        gender: true,
        level: true,
        role: true,
      }"
      :items="GET_USERS_LIST.results"
      :fields="fields"
      :rows="GET_USERS_LIST.count"
      :edit-btn="false"
      :first-params="params"
      @refresh="FETCH_USERS_LIST"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @delete-button-clicked="modalDeleteUser"
      @add-button-clicked="addUser"
    />
    <add-modal
      :default-params="params"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
// import HeadCards from '@/components/HeadCards.vue'
import FullTable from '@/components/FullTable.vue'
import AddModal from '../components/modals/AddUserModal.vue'

export default {
  name: 'User',
  components: {
    FullTable,
    // HeadCards,
    AddModal,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isEdit: false,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'ID',
          key: 'contact.id',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          label: 'users.roles',
          key: 'roles',
        },
        {
          label: 'users.phone_number',
          key: 'contact.phone',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
  },
  beforeDestroy() {
    this.SET_USERS_LIST([])
  },
  methods: {
    ...mapMutations('users', ['SET_USERS_LIST']),
    ...mapActions('users', ['FETCH_USERS_LIST', 'DELETE_USER']),
    addUser() {
      this.$bvModal.show('AddModalUser')
    },
    refresh() {
      this.FETCH_USERS_LIST(this.params)
    },
    modalDeleteUser(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER(id)
            .then(() => {
              this.$_successToast('Успешно удалено')
              this.params.page = 1
              this.FETCH_USERS_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        }
      })
    },
  },
}
</script>
